@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&family=Fira+Mono&display=swap");

:root {
  --theme-primary-color: #4d5bce;
  --theme-secondary-color: #43d9ad;
  --theme-link-color: #e99287;
  --theme-primary-color-back: #011627;
  --theme-text-color: #fff;
  --theme-text-color-com: #8f8c8c;
  --theme-background-color: #43d9ad;

  //game
  --game-area-background: rgba(0, 0, 0, 0.8); /* Fond noir semi-transparent */
  --game-area-border: rgba(
    255,
    255,
    255,
    0.5
  ); /* Bordure blanche semi-transparente */
  --game-area-box-shadow: rgba(0, 0, 0, 0.8); /* Ombre prononcée */
  --plateau-background: rgba(0, 0, 0, 0.9); /* Fond noir plus opaque */
  --plateau-box-shadow: rgba(0, 0, 0, 0.8); /* Légère ombre */
  --plateau-border: rgba(
    255,
    255,
    255,
    0.5
  ); /* Bordure blanche semi-transparente */

  --game-over-border: linear-gradient(
    45deg,
    #fe6b8b 30%,
    #ff8e53 90%
  ); /* Dégradé rouge pour la bordure */
  --game-over-box-shadow: rgba(0, 0, 0, 0.8); /* Ombre rouge plus prononcée */
  --game-over-background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
}

body {
  background-image: url("./assets/bg-port.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom; /* Position image at center bottom */
  min-height: 100vh; /* Set minimum height to viewport height */
  margin: 0; /* Remove any default margins */
  display: flex; /* Enable flexbox for content positioning */
  flex-direction: column; /* Stack content vertically */
  color: var(--theme-text-color);
  &.dark {
  }
}

/* Appliquer Montserrat aux titres */
h1,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: var(--theme-secondary-color);
}

h2 {
  font-family: "Montserrat", sans-serif;
  color: var(--theme-link-color);
}
/* Appliquer Fira Mono au reste du texte */
body,
p,
a,
span {
  font-family: "Fira Mono", monospace;
}
li {
  font-family: "Fira Mono", monospace;
  margin-bottom: 10px; /* Ajustez cette valeur selon l'espace souhaité */
  font-size: 1.1rem; /* Ajustez cette valeur selon la taille de police souhaitée */
  letter-spacing: 0.5px; /* Ajustez cette valeur selon l'espacement souhaité */
}
/* Width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--theme-link-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--theme-primary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--theme-primary-color-back);
}

.MuiListItemText-root {
  font-size: 0.875rem; // Default font size
}

// Optionally, customize font size for specific elements within ListItemText className="my-list-text"
.MuiListItemText-root span {
  font-size: 0.875rem;
}

.legal-notice {
  text-align: center;
  padding: 50px;
  background-color: transparent;
  border-radius: 8px;
  margin: auto;

  h2 {
    color: var(--theme-primary-color);
    margin-bottom: 20px;
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .list-item {
    margin-top: 20px;
    padding: auto;
    margin-bottom: 20px;
    margin: auto;

    &:last-child {
      margin-bottom: 0;
    }

    .primary-text {
      padding: auto;
      font-weight: bold;
      color: var(--theme-secondary-color);
      margin: auto;
    }

    .secondary-text {
      padding: auto;
      color: var(--theme-text-color);
      margin-top: 5px;
      margin: auto;
    }
  }
}

.MuiListItemText-root p {
  font-size: 0.875rem;
}

@media (max-width: 720px) {
  .my-list-text {
    font-size: 0.7rem;
  }
}

.navBar {
  top: 0;
  background-color: transparent;

  .no-logo {
    cursor: pointer;
  }
  .navbar_link {
    font-weight: bold;
    font-size: 1.2rem;
    color: aliceblue;
  }
  .navbar_link.active {
    padding: 2;
    background-color: var(--theme-background-color);
    color: var(--theme-primary-color-back);
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.footer {
  background-color: transparent;
}
.comment {
  color: var(--theme-text-color-com);
}
.home {
  display: flex;
  justify-content: space-between;
  .left-text {
    .title-chevron {
      color: var(--theme-primary-color);
      font-weight: bold;
    }
    .comment {
      color: var(--theme-text-color-com);
    }
    .const {
      color: var(--theme-primary-color);
    }
    .name-const {
      color: var(--theme-secondary-color);
    }
    .link {
      color: var(--theme-link-color);
    }
    .link:hover {
      cursor: pointer;
    }
  }
}
.selected-link {
  color: var(--theme-link-color);
}
.snake-dot {
  position: absolute;

  width: 4%; /* Ajustement de la taille du point du serpent */
  height: 4%; /* Ajustement de la taille du point du serpent */
  background-color: var(--theme-primary-color); /* Couleur primaire du thème */
  border-radius: 50%; /* Transformation en cercle complet */
  box-shadow: 0px 0px 10px var(--theme-primary-color);
}
.snake-food {
  position: absolute;

  width: 4%; /* Ajustement de la taille de la nourriture */
  height: 4%; /* Ajustement de la taille de la nourriture */
  background-color: var(
    --theme-secondary-color
  ); /* Couleur secondaire du thème */
  border-radius: 50%; /* Transformation en cercle complet */
  box-shadow: 0px 0px 10px var(--theme-secondary-color);
}

.custom-drawer {
  .custom-list {
    height: 100vh;
    background-color: var(--theme-secondary-color);

    .MuiListItem-root {
      color: var(--theme-primary-color-back);
      font-weight: bold;

      &:hover {
        background-color: var(--theme-primary-color-back);
        color: var(--theme-secondary-color);
        font-weight: bold;
      }

      &.Mui-selected {
        background-color: var(--theme-primary-color);
        color: var(--theme-link-color);
        font-weight: bold;
      }
    }
  }
}

.second-drawer {
  .drawer-list {
    background-color: var(--theme-primary-color);
    height: 100vh;

    .drawer-item {
      &.selected {
        background-color: var(--theme-primary-color);
        /* Ajoutez ici les styles pour les liens sélectionnés dans le Drawer */
        color: var(--theme-link-color);
        font-weight: bold;
      }

      .drawer-link {
        border-radius: "20px";
        color: white;
        font-weight: bold;
        text-decoration: none;
      }
      .drawer-link.active {
        border-radius: 5px;
        background-color: var(--theme-secondary-color);
        color: var(--theme-primary-color-back) !important;
        font-weight: bold;
      }
    }
  }
}

.game-area {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  border-radius: 15px;
  background-color: var(--game-area-background);
  border: 3px solid var(--game-area-border);
  box-shadow: 0px 8px 16px var(--game-area-box-shadow);

  .plateau {
    border-radius: 15px;
    background-color: var(--plateau-background);
    box-shadow: 0px 4px 8px var(--plateau-box-shadow);
    position: relative;
    border: 3px solid var(--plateau-border);

    .game-over {
      border: 5px solid var(--game-over-border);
      box-shadow: 0 0 10px whitesmoke;
      background: var(--game-over-background);
      padding: 30px;
      border-radius: 20px;
      text-align: center;

      .score-go {
        color: white;
        font-size: 2rem;
        margin-top: 15px;
      }
      .title-go {
        color: white;
        font-size: 2rem;
        margin-top: 15px;
      }

      .divider-go {
        background-color: black;
        height: 5px;
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }
  }
}

// Styles pour la liste d'intérêts
.interest-list {
  padding: 20px;
  background-color: var(--theme-primary-color-back);
}

// Styles pour les conteneurs d'images
.interest-image-container {
  flex-direction: column;
  padding: 10px;
}

// Styles pour les images
.interest-image {
  border: 2px solid var(--theme-primary-color);
  transition: transform 0.3s ease;
  width: 300px;
  height: 150px;
  border-radius: 5px;
  &:hover {
    transform: scale(1.05);
  }

  /* Media query for screens 768px and smaller */
  @media (max-width: 768px) {
    width: 200px;
    height: 100px;
  }
}

// Styles pour les titres
.interest-title {
  margin-bottom: 10px;
  color: var(--theme-text-color);
  text-align: center;
}

// Styles pour les dialogues
.interest-dialog {
  .dialog-title {
    background-color: var(--theme-primary-color);
    color: white;
  }

  .dialog-content {
    background-color: white;
    color: var(--theme-text-color);
  }

  .dialog-text {
    font-size: 22px;
    line-height: 1.5;
  }
}

.box-image-projet2 {
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; // Centrer la boîte dans la grille
}
.box-image-projet {
  width: 300px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; // Centrer la boîte dans la grille
}
.card-ball {
  border: 2px solid var(--theme-primary-color);
}
.custom-link {
  color: var(--theme-secondary-color); /* Couleur du lien */
  text-decoration: none; /* Supprime le soulignement par défaut */
}
.large-text-list li {
  margin-bottom: 10px; /* Ajustez cette valeur selon l'espace souhaité */
  font-size: 1.2rem; /* Ajustez cette valeur selon la taille de police souhaitée */
}

.custom-link:hover {
  text-decoration: underline; /* Soulignez le lien au survol */
}

.developer-grid {
  background-color: var(--theme-primary-color-back);
}
.image-projet {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid var(--theme-primary-color);
  transition: transform 0.3s ease;
  border-radius: 5px;
  &:hover {
    transform: scale(1.1);
  }
}

@mixin retro-styles {
  text-shadow: 2px 2px var(--theme-link-color);
  border: 2px solid var(--theme-secondary-color);
  box-shadow: 0 0 10px var(--theme-link-color);
}

// Component styles
.dialog-ball {
  padding: 5px;
  background-color: var(--theme-primary-color-back);
  color: var(--theme-text-color);
  font-family: "Press Start 2P", cursive; // Retro gaming font

  .dialog-content {
    .card-ball {
      padding: 2px;
    }
  }

  .title-project {
    text-align: center;
    color: var(--theme-secondary-color);
    @include retro-styles;
  }

  .image-projet {
    cursor: pointer;
    @include retro-styles;
  }

  .box-image-projet2 {
    justify-content: center;
  }

  .custom-link {
    color: var(--theme-link-color);
    text-decoration: none;
    font-size: 1.2em;

    &:hover {
      text-decoration: underline;
      color: var(--theme-secondary-color);
    }
  }

  .tech-logo {
    width: 100%;
    height: auto;
    @include retro-styles;
  }
}

/* Styles pour les dialogues */
.interest-dialog {
  .dialog-title {
    background-color: var(--theme-primary-color);
    color: white;
    padding: 16px;
    border-radius: 4px 4px 0 0;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .dialog-content {
    background-color: var(--theme-primary-color-back);
    color: var(--theme-text-color);
    padding: 20px;
    border-radius: 0 0 4px 4px;
  }

  .dialog-text {
    font-size: 1.2rem;
    line-height: 1.6;
    font-weight: bold;
    color: var(--theme-text-color);
  }
}

.dialog-card {
  background-color: var(--theme-background-color);
  color: var(--theme-text-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
}

.dialog-card-content {
  font-size: 1rem;
  line-height: 1.6;
}

// EFFETS
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 1s ease-out;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.zoom-in {
  animation: zoomIn 0.5s ease-out; /* Durée de l'animation */
}

// Définir l'animation de frappe
@keyframes typing {
  from {
    width: 0; // Commencer avec une largeur de texte de 0
  }
  to {
    width: 100%; // Augmenter progressivement la largeur du texte jusqu'à 100%
  }
}

// Appliquer l'animation aux éléments avec la classe "typing-animation"
.typing-animation {
  overflow: hidden; // Cacher le texte qui déborde
  white-space: nowrap; // Empêcher le texte de passer à la ligne
  animation: typing 2s steps(40, end); // Utiliser l'animation "typing" avec une durée de 2 secondes
}
.typing-animation2 {
  overflow: hidden; // Cacher le texte qui déborde
  animation: typing 2s steps(40, end); // Utiliser l'animation "typing" avec une durée de 2 secondes
}

.dialog-content p {
  animation: typing 2s steps(60, end);
}
